import { SvgIcon } from "@material-ui/core";

function AchivmentsIcon(props) {
	return (
		<SvgIcon viewBox="0 0 512 512" {...props}>
			<path
				d="m467 0h-43.238281l-30.695313 30h73.933594c8.277344 0 15 6.722656 15 15v22.851562c0 30.234376-10.398438 59.882813-29.296875 83.496094-16.128906 20.171875-37.601563 35.140625-62.09375 43.300782l-21.152344 7.046874c-7.851562 2.621094-12.101562 11.117188-9.492187 18.984376 2.09375 6.285156 7.953125 10.253906 14.238281 10.253906 1.566406 0 3.164063-.25 4.746094-.761719l21.152343-7.0625c29.984376-9.988281 56.277344-28.328125 76.039063-53.011719 23.117187-28.914062 35.859375-65.230468 35.859375-102.246094v-22.851562c0-24.8125-20.1875-45-45-45zm0 0"
				fill="#ff7816"
			/>
			<path
				d="m142.542969 201.695312-21.152344-7.03125c-24.492187-8.175781-45.964844-23.144531-62.09375-43.316406-18.898437-23.613281-29.296875-53.261718-29.296875-83.496094v-22.851562c0-8.277344 6.722656-15 15-15h77.515625l-34.277344-30h-43.238281c-24.8125 0-45 20.1875-45 45v22.851562c0 37.015626 12.742188 73.332032 35.859375 102.246094 19.761719 24.683594 46.054687 43.023438 76.039063 53.027344l21.152343 7.046875c1.582031.511719 3.179688.761719 4.746094.761719 6.285156 0 12.144531-3.96875 14.238281-10.253906 2.609375-7.867188-1.640625-16.363282-9.492187-18.984376zm0 0"
				fill="#ff9f00"
			/>
			<path
				d="m302 292.800781v22.199219c0 8.398438 6.597656 15 15 15 24.902344 0 45 20.097656 45 45v30c0 8.398438-6.597656 15-15 15h-182c-8.402344 0-15-6.601562-15-15v-30c0-24.902344 20.097656-45 45-45 8.402344 0 15-6.601562 15-15v-22.199219c-55.800781-17.703125-96.597656-66.902343-103.199219-125.402343l-18.601562-167.398438h335.601562l-18.601562 167.398438c-6.601563 58.5-47.398438 107.699218-103.199219 125.402343zm0 0"
				fill="#ffd400"
			/>
			<path
				d="m347 420h-91v-420h167.800781l-18.601562 167.398438c-6.601563 58.5-47.398438 107.699218-103.199219 125.402343v22.199219c0 8.398438 6.597656 15 15 15 24.902344 0 45 20.097656 45 45v30c0 8.398438-6.597656 15-15 15zm0 0"
				fill="#ff9f00"
			/>
			<path
				d="m330.398438 113.097656c-1.800782-5.699218-6.597657-9.597656-12.296876-10.496094l-33.601562-4.800781-15-29.703125c-2.699219-5.097656-8.101562-7.5-13.5-7.5s-10.800781 2.402344-13.5 7.5l-15 29.703125-33.601562 4.796875c-5.699219.902344-10.496094 4.800782-12.296876 10.5-1.5 5.402344 0 11.398438 4.199219 15.300782l24 23.101562-5.699219 32.398438c-.902343 5.402343 1.199219 11.101562 6 14.703124 4.5 3.296876 10.800782 3.898438 15.597657 1.199219l30.300781-15.601562 30.300781 15.601562c5.097657 2.699219 11.097657 2.097657 15.601563-1.199219 4.796875-3.601562 6.898437-9.300781 5.996094-14.703124l-5.699219-32.398438 24-23.101562c4.199219-3.898438 5.703125-9.898438 4.199219-15.300782zm0 0"
				fill="#ff9f00"
			/>
			<path
				d="m392 405v90.800781h-272v-90.800781c0-8.402344 6.597656-15 15-15h242c8.402344 0 15 6.597656 15 15zm0 0"
				fill="#ec5569"
			/>
			<path
				d="m286.300781 199.800781-30.300781-15.601562v-123.601563c5.398438 0 10.800781 2.402344 13.5 7.5l15 29.703125 33.601562 4.800781c5.699219.898438 10.496094 4.796876 12.296876 10.496094 1.5 5.402344 0 11.402344-4.199219 15.300782l-24 23.101562 5.699219 32.402344c.902343 5.398437-1.199219 11.097656-6 14.699218-4.5 3.296876-10.5 3.898438-15.597657 1.199219zm0 0"
				fill="#ff7816"
			/>
			<path
				d="m392 405v90.800781h-136v-105.800781h121c8.402344 0 15 6.597656 15 15zm0 0"
				fill="#cc2e43"
			/>
			<path
				d="m422 497c0 8.398438-6.597656 15-15 15h-302c-8.402344 0-15-6.601562-15-15 0-8.402344 6.597656-15 15-15h302c8.402344 0 15 6.597656 15 15zm0 0"
				fill="#697c86"
			/>
			<path
				d="m422 497c0 8.398438-6.597656 15-15 15h-151v-30h151c8.402344 0 15 6.597656 15 15zm0 0"
				fill="#465a61"
			/>
		</SvgIcon>
	);
}

export default AchivmentsIcon;
