import React, { useEffect, useState } from "react";
import {
	AppBar,
	Button,
	CircularProgress,
	IconButton,
	makeStyles,
	TextField,
	Toolbar,
	Typography,
} from "@material-ui/core";
import logo from "../../Assets/logo.png";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
} from "react-router-dom";
import { Directus } from "@directus/sdk";
import Auth from "./Components/Auth/Auth";
import Admin from "./Components/Admin/Admin";
import { ExitToAppOutlined } from "@material-ui/icons";
import Game from "./Components/Game/Game";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		padding: theme.spacing(0, 0, 0, 1),
		flexGrow: 1,
	},
	logo: {
		height: 60,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(4, 2),
		maxWidth: 1225,
		margin: "auto",
		textAlign: "center",
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	card: {
		// minWidth: 275,
		background: "#000",
	},
	toolbar: {
		borderBottom: "1px solid #222",
		padding: theme.spacing(0, 2, 0, 1),
		[theme.breakpoints.up(1225)]: {
			padding: "0 calc(calc(calc(100% - 1225px) / 2) + 8px)",
		},
	},
	authContainer: {
		marginTop: "calc(60vh - 180px)",
	},
	authForm: {
		display: "grid",
		justifyItems: "center",
		"& .MuiTextField-root": {
			width: "250px",
			margin: theme.spacing(1),
		},
		"& .MuiButton-root": {
			width: "250px",
			margin: theme.spacing(1),
			border: "1px solid #fff",
		},
	},
}));

function AdminApp() {
	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [server, setServer] = useState(null);
	const [me, setMe] = useState(null);
	const [directus, setDirectus] = useState(null);
	const [errorMessage, setErrorMessage] = useState("");
	const [auth, setAuth] = React.useState(true);
	// const [placesList, setPlacesList] = React.useState([]);

	const history = useHistory();

	const onError = () => {
		setLoading(false);
		setAuth(false);
		history.push("/admin/auth");
	};

	const updateUserData = async () => {
		let sData = await directus.server.info();
		let mData = await directus.users.me.read();
		setServer(sData);
		setMe(mData);
		setLoading(false);
	};

	// const updatePlacesData = async () => {
	// 	let placesData = await directus.items("playgrounds").readMany({
	// 		fields: ["*.*"],
	// 		limit: -1,
	// 		filter: {
	// 			_and: [
	// 				{
	// 					game: {
	// 						_eq: 1,
	// 					},
	// 				},
	// 				{
	// 					active: {
	// 						_eq: true,
	// 					},
	// 				},
	// 			],
	// 		},
	// 	});
	// 	if (placesData?.data) {
	// 		setPlacesList(placesData.data);
	// 	}
	// };

	const updateData = async () => {
		if (directus) {
			try {
				await updateUserData();
				// await updatePlacesData();
				setAuth(true);
			} catch (error) {
				onError();
			}
		}
	};

	useEffect(() => {
		setDirectus(new Directus("https://db.izba-club.ru/"));
	}, []);

	useEffect(() => {
		updateData();
	}, [directus]);

	const onAuth = async (email, password) => {
		directus.auth
			.login(
				{
					email: email,
					password: password,
				},
				{
					refresh: {
						auto: true,
					},
					mode: "json",
				}
			)
			.then(() => {
				setErrorMessage("");
				updateData();
				history.push("/admin");
			})
			.catch((error) => {
				setErrorMessage(error.message);
			});
	};

	const onCreateGame = async (type, count) => {
		let gameData = await directus.items("izba_rating").createOne({
			active: false,
			type: type,
			// playground: place,
			count: count,
			host: me.id,
			date: moment().format(),
		});
		history.push(`/admin/game/${gameData.id}`);
	};

	const onLoadGame = async (fileData) => {
		let fr = new FileReader();
		fr.onload = async (e) => {
			let lines = e.target.result;
			var newData = JSON.parse(lines);
			if (newData?.id) {
				let gameData = await directus
					.items("izba_rating")
					.updateOne(newData.id, newData);
				history.push(`/admin/game/${gameData.id}`);
			}
		};
		fr.readAsText(fileData);
	};

	const onLogout = async () => {
		try {
			await directus.auth.logout();
		} catch (error) {
			console.log(error);
		} finally {
			onError();
		}
	};

	if (loading)
		return (
			<div style={{ margin: "32px auto", width: "fit-content" }}>
				<CircularProgress color="secondary" />
			</div>
		);
	else
		return (
			<div className={classes.root}>
				<AppBar position="static">
					<Toolbar className={classes.toolbar}>
						{/* <IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="menu"
					>
						<MenuIcon />
					</IconButton> */}
						<img className={classes.logo} src={logo} alt={"logo"} />
						<Typography variant="h6" className={classes.title}>
							<Link component={Button} color="inherit" to="/admin">
								Главная
							</Link>
						</Typography>
						<div></div>
						<div>
							{auth ? (
								<IconButton
									edge="end"
									color="inherit"
									aria-label="Выйти"
									onClick={onLogout}
								>
									<ExitToAppOutlined />
								</IconButton>
							) : (
								<></>
							)}
						</div>
					</Toolbar>
				</AppBar>
				<Switch>
					<Route
						path="/admin/auth"
						children={<Auth error={errorMessage} onAuth={onAuth} />}
					/>
					<Route
						path="/admin/game/:gameId"
						children={<Game directus={directus} />}
					/>
					<Route
						path="/admin"
						children={
							directus ? (
								<Admin
									directus={directus}
									me={me}
									onError={onError}
									onCreateGame={onCreateGame}
									onLoadGame={onLoadGame}
									// placesList={placesList}
								/>
							) : (
								<></>
							)
						}
					/>
				</Switch>
			</div>
		);
}

export default AdminApp;
