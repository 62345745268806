import React, { useEffect, useState } from "react";
import {
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	TablePagination,
	Chip,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import WinPNG from "../../Assets/cards/win.png";

const useStyles = makeStyles((theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(4, 2),
		maxWidth: 1225,
		margin: "auto",
		textAlign: "center",
	},
	container: {
		// marginTop: theme.spacing(2),
		// maxHeight: "500px"
	},
	table: {
		"& *": {
			fontSize: "1rem",
		},
		// marginTop: theme.spacing(2),
		// maxHeight: "500px"
	},
	chips: {
		margin: theme.spacing(2, 0, 1),
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
}));

function Rating() {
	const classes = useStyles();

	const [ratingData, setRatingData] = useState([]);
	const [infoData, setInfoData] = useState({});
	const [ratingPage, setRatingPage] = React.useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	useEffect(() => {
		async function fetchData() {
			let ratingRequest = await fetch(
				"https://db.izba-club.ru/rating_api/rating?access_token=ratingizbaru",
				{
					headers: {
						accept: "application/json, text/plain, */*",
						"cache-control": "no-store",
					},
					method: "GET",
				},
			);
			setRatingData(await ratingRequest.json());
		}
		fetchData();
	}, []);

	useEffect(() => {
		async function fetchData() {
			let infoRequest = await fetch(
				"https://db.izba-club.ru/items/izba_info?access_token=ratingizbaru",
				{
					headers: {
						accept: "application/json, text/plain, */*",
						"cache-control": "no-store",
					},
					method: "GET",
				},
			);
			let infoData = await infoRequest.json();
			setInfoData(infoData.data);
		}
		fetchData();
	}, []);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSelectRange = (range) => () => {
		setRatingPage(ratingData.findIndex((r) => r.name == range.name));
		setPage(0);
	};

	return (
		<main className={classes.content}>
			<Typography variant="h3">РЕЙТИНГ</Typography>
			<img
				src={WinPNG}
				alt=""
				style={{ width: "175px", height: "175px", objectFit: "contain" }}
			/>
			{!!infoData?.prize_fund && (
				<Typography
					variant="h4"
					dangerouslySetInnerHTML={{ __html: infoData.prize_fund }}
				></Typography>
			)}
			{ratingData.length == 0 ? (
				<Typography variant="caption" color="textSecondary">
					Подождите, идёт загрузка...
				</Typography>
			) : (
				<>
					<div className={classes.chips}>
						{ratingData.map((range, index) => (
							<Chip
								size="small"
								label={range.name}
								variant={index === ratingPage ? "default" : "outlined"}
								onClick={
									index === ratingPage ? undefined : handleSelectRange(range)
								}
							/>
						))}
					</div>
					<TableContainer className={classes.container} component={Paper}>
						<Table
							className={classes.table}
							size="small"
							aria-label="Рейтинг"
							// stickyHeader
						>
							<TableHead>
								<TableRow>
									<TableCell align="center">№</TableCell>
									<TableCell align="center">Карта</TableCell>
									<TableCell align="center">Баллы</TableCell>
									<TableCell align="center">Всего сыграно игр</TableCell>
									<TableCell align="center">Всего побед</TableCell>
									<TableCell align="center">
										Коэффициент эффективности
									</TableCell>
									<TableCell align="center">Дополнительные баллы</TableCell>
									<TableCell align="center">Штрафы</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ratingData.length > 0 &&
									ratingData[ratingPage].data
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((iCard) => (
											<TableRow key={iCard.card}>
												<TableCell align="center">{iCard._n}</TableCell>
												<TableCell align="left" component="th" scope="row">
													<Link
														to={`/card/${iCard.card}`}
														style={{ color: "#C69B66" }}
													>
														{iCard.card}. {iCard.nickname}
													</Link>
												</TableCell>
												<TableCell align="center">{iCard._points}</TableCell>
												<TableCell align="center">{iCard.all_count}</TableCell>
												<TableCell align="center">{iCard.all_win}</TableCell>
												<TableCell align="center">
													{iCard._efficiency}
												</TableCell>
												{/* <TableCell align="center">{iCard._citizen}</TableCell>
											<TableCell align="center">{iCard._doctor}</TableCell>
											<TableCell align="center">{iCard._commissar}</TableCell>
											<TableCell align="center">{iCard._putana}</TableCell>
											<TableCell align="center">{iCard._kamikaze}</TableCell>
											<TableCell align="center">{iCard._martyr}</TableCell>
											<TableCell align="center">{iCard._mafia}</TableCell>
											<TableCell align="center">{iCard._don}</TableCell>
											<TableCell align="center">{iCard._maniac}</TableCell>
											<TableCell align="center">{iCard.oi}</TableCell>
											<TableCell align="center">{iCard.ob}</TableCell> */}
												<TableCell align="center">
													{iCard.additional_points}
												</TableCell>
												<TableCell align="center">{iCard.penalty}</TableCell>
											</TableRow>
										))}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[20, 50, 100, 200, 500]}
						component="div"
						count={ratingData[ratingPage].data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</>
			)}
		</main>
	);
}

export default Rating;
