import React, { useEffect } from "react";
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	Slider,
	TextField,
	Typography,
} from "@material-ui/core";

import {
	GAME_TYPES,
	GAME_TYPE_RATING,
	GAME_TYPE_TEST,
	GAME_TYPE_TOURNAMENT,
	LOCAL_STORAGE_COUNT,
	LOCAL_STORAGE_PLACE,
	LOCAL_STORAGE_RECOVERY,
	LOCAL_STORAGE_TYPE,
} from "../../constants";

const useStyles = makeStyles((theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(4, 2),
		maxWidth: 1225,
		margin: "auto",
		textAlign: "center",
	},
	formContainer: {
		display: "grid",
		width: "fit-content",
		margin: "auto",
		padding: theme.spacing(1),
		border: "1px solid #333",
		borderRadius: "5px",
		marginTop: theme.spacing(4),
		maxWidth: "286px",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 250,
		textAlign: "initial",
	},
	button: {
		margin: theme.spacing(1, 0, 0),
		border: "1px solid #fff",
	},
}));

function Admin({ me, onCreateGame, onLoadGame }) {
	const classes = useStyles();

	const [recovery, setRecovery] = React.useState(false);
	const [type, setType] = React.useState(GAME_TYPE_RATING);
	// const [place, setPlace] = React.useState("");
	const [count, setCount] = React.useState(3);
	const fileInput = React.useRef();

	useEffect(() => {
		let cached_recovery = !!localStorage.getItem(LOCAL_STORAGE_RECOVERY);
		let cached_type = localStorage.getItem(LOCAL_STORAGE_TYPE);
		// let cached_place = localStorage.getItem(LOCAL_STORAGE_PLACE);
		let cached_count = parseInt(localStorage.getItem(LOCAL_STORAGE_COUNT)) || 3;

		setRecovery(cached_recovery);

		if (cached_type && GAME_TYPES.includes(cached_type)) setType(cached_type);

		if (cached_count <= 16 && cached_count >= 3) setCount(cached_count);

		// if (!place && placesList.length > 0) {
		// 	if (cached_place && placesList.map((pl) => pl.id).includes(cached_place))
		// 		setPlace(cached_place);
		// 	else setPlace(placesList[0].id);
		// }
	}, [me]);

	const handleRecoveryChange = (event) => {
		let newValue = event.target.checked;
		setRecovery(newValue);
		localStorage.setItem(LOCAL_STORAGE_RECOVERY, newValue);
	};

	// const handlePlaceChange = (event) => {
	// 	let newValue = parseInt(event.target.value);
	// 	setPlace(newValue);
	// 	localStorage.setItem(LOCAL_STORAGE_PLACE, newValue);
	// };

	const handleTypeChange = (event) => {
		let newValue = event.target.value;
		setType(newValue);
		localStorage.setItem(LOCAL_STORAGE_TYPE, newValue);
	};

	const handleCountChange = (event) => {
		let newValue = parseInt(event.target.value);
		setCount(newValue);
		localStorage.setItem(LOCAL_STORAGE_COUNT, newValue);
	};

	function valuetext(value) {
		return `${value} чел.`;
	}

	const createGame = async () => {
		onCreateGame(type, count);
	};

	const loadGame = async () => {
		onLoadGame(fileInput.current.files[0]);
	};

	return (
		<div className={classes.content}>
			{/* <Typography variant="h3">Привет, {me?.first_name}!</Typography> */}
			<div className={classes.formContainer}>
				{/* <FormControl className={classes.formControl}>
					<InputLabel id="place-select-label">Текущая площадка</InputLabel>
					<Select
						labelId="place-select-label"
						id="place-select"
						value={place}
						onChange={handlePlaceChange}
					>
						{placesList &&
							placesList.map((place) => (
								<MenuItem key={place.id} value={place.id}>
									{place.name}
								</MenuItem>
							))}
					</Select>
				</FormControl> */}
				<FormControl className={classes.formControl}>
					<InputLabel id="type-select-label">Тип игры</InputLabel>
					<Select
						labelId="type-select-label"
						id="type-select"
						value={type}
						onChange={handleTypeChange}
					>
						<MenuItem key={GAME_TYPE_RATING} value={GAME_TYPE_RATING}>
							Рейтинговая
						</MenuItem>
						<MenuItem key={GAME_TYPE_TOURNAMENT} value={GAME_TYPE_TOURNAMENT}>
							Турнир
						</MenuItem>
						<MenuItem key={GAME_TYPE_TEST} value={GAME_TYPE_TEST}>
							Тест
						</MenuItem>
					</Select>
				</FormControl>
				<FormControl className={classes.formControl}>
					<TextField
						id="count-input"
						label="Кол-во игроков"
						variant="standard"
						type="number"
						value={count}
						onChange={handleCountChange}
						InputProps={{
							inputProps: {
								max: 16,
								min: 3,
							},
						}}
					/>
					{/* <Typography
						id="count-slider-label"
						gutterBottom
						color="textSecondary"
						variant="caption"
						component="label"
					>
						Кол-во игроков
					</Typography>
					<Slider
						aria-labelledby="count-slider-label"
						getAriaValueText={valuetext}
						defaultValue={3}
						step={1}
						min={3}
						max={15}
						value={count}
						onChange={handleCountChange}
						valueLabelDisplay="off"
						marks={[3, 5, 7, 9, 11, 13, 15].map((count) => ({
							value: count,
							label: count,
						}))}
					/> */}
				</FormControl>
				<FormControl className={classes.formControl}>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox checked={recovery} onChange={handleRecoveryChange} />
							}
							label="Запоминать номера"
						/>
					</FormGroup>
				</FormControl>
				<Button className={classes.button} onClick={createGame}>
					Создать игру
				</Button>
			</div>

			<div className={classes.formContainer}>
				<FormControl className={classes.formControl}>
					<Typography
						id="count-slider-label"
						gutterBottom
						color="textSecondary"
						variant="caption"
						component="label"
					>
						Файл с сохранённой игрой
					</Typography>
					<input
						labelId="file-select-label"
						id="file-select"
						type="file"
						ref={fileInput}
					/>
				</FormControl>
				<Button className={classes.button} onClick={loadGame}>
					Загрузить игру
				</Button>
			</div>
		</div>
	);
}

export default Admin;
