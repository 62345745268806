import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./Components/App/App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import {
	BrowserRouter as Router,
	Switch,
	Route,
  } from "react-router-dom";
import AdminApp from "./Components/AdminApp/AdminApp";
// import * as serviceWorker from './serviceWorker';
import swDev from "./swDev";

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Router>
				<Switch>
					<Route path="/admin" children={<AdminApp />} />
					<Route path="/" children={<App />} />
				</Switch>
			</Router>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

swDev();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorker.unregister();
// reportWebVitals();
