export default function swDev() {
	let swUrl = `${process.env.PUBLIC_URL}/_sw.js`;
	navigator.serviceWorker.register(swUrl).then((response) => {
		console.warn("response", response);
		try {
			response.update();
		} catch (error) {
			console.log(error);
		}
	});
}
