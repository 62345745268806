import React from "react";
import {
	AppBar,
	Button,
	makeStyles,
	Toolbar,
	Typography,
} from "@material-ui/core";
import logo from "../../Assets/logo.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Profile from "../Profile/Profile";
import Rating from "../Rating/Rating";
import League from "../AdminApp/Components/League/League";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		padding: theme.spacing(0, 0, 0, 1),
		flexGrow: 1,
	},
	logo: {
		height: 60,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(4, 2),
		maxWidth: 1225,
		margin: "auto",
		textAlign: "center",
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: "flex-end",
	},
	card: {
		// minWidth: 275,
		background: "#000",
	},
	toolbar: {
		borderBottom: "1px solid #222",
		padding: theme.spacing(0, 2, 0, 1),
		[theme.breakpoints.up(1225)]: {
			padding: "0 calc(calc(calc(100% - 1225px) / 2) + 8px)",
		},
	},
}));

function App() {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Toolbar className={classes.toolbar}>
					{/* <IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="menu"
					>
						<MenuIcon />
					</IconButton> */}
					<a color="inherit" href="https://izba-club.ru/">
						<img className={classes.logo} src={logo} alt={"logo"} />
					</a>
					<Typography variant="h6" className={classes.title}>
						<Link component={Button} color="inherit" to="/">
							Рейтинг
						</Link>
						{/* <Link component={Button} color="inherit" to="/league">
							Лига
						</Link> */}
					</Typography>
					<div>{/* <Button color="inherit">Главная</Button> */}</div>
					{/* <Button color="inherit">Login</Button> */}
					<div>
						{/* <IconButton
								edge="end"
								// className={classes.menuButton}
								color="inherit"
								aria-label="help"
							>
								<HelpOutline />
							</IconButton> */}
					</div>
				</Toolbar>
			</AppBar>
			<Switch>
				<Route path="/card/:card" children={<Profile />} />
				{/* <Route path="/league" children={<League />} /> */}
				<Route path="/" children={<Rating />} />
			</Switch>
		</div>
	);
}

export default App;
