import React, { useState } from "react";
import { Button, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	authContainer: {
		marginTop: "calc(55vh - 180px)",
	},
	authForm: {
		display: "grid",
		justifyItems: "center",
		"& .MuiTextField-root": {
			width: "250px",
			margin: theme.spacing(1),
		},
		"& .MuiButton-root": {
			width: "250px",
			margin: theme.spacing(1),
			border: "1px solid #fff",
		},
	},
    error:{
        color:"#ff0000"
    }
}));

function Auth({ error, onAuth }) {
	const classes = useStyles();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};

	const handleAuth = () => {
		onAuth(email, password);
	};

	return (
		<div className={classes.authContainer}>
			<form
				className={classes.authForm}
				noValidate
				// autoComplete="off"
			>
				<TextField
					id="email"
					type="email"
					label="Почта"
					variant="outlined"
                    error={!!error}
					value={email}
					onChange={handleEmailChange}
				/>
				<TextField
					id="password"
					type="password"
					label="Пароль"
					variant="outlined"
					autoComplete="current-password"
                    error={!!error}
					value={password}
					onChange={handlePasswordChange}
				/>
				<Button onClick={handleAuth}>Войти</Button>
                <div className={classes.error}>{error}</div>
			</form>
		</div>
	);
}

export default Auth;
