import { createTheme } from "@material-ui/core/styles";
import { ruRU } from "@material-ui/core/locale";
// A custom theme for this app
const theme = createTheme(
	{
		palette: {
			type: "dark",
			primary: {
				main: "#000",
				light: "#fff",
				dark: "#fff",
			},
			secondary: {
				main: "#b5ecfb",
				light: "#61dafb",
				dark: "#21a1c4",
			},
			// error: {
			// 	main: red.A400,
			// },
			background: {
				default: "#000",
			},
		},
		overrides: {
			MuiFormLabel: {
				root: {
					"&$focused": {
						color: "#fff",
					},
					// Mui:{
					// 	focused:{
					// 		color:"#fff"
					// 	}
					// }
				},
			},
			MuiOutlinedInput: {
				root: {
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: "#555",
					},
				},
			},
			MuiSlider:{
				root: {
					color:"#fff"
				},
			},
			PrivateValueLabel:{
				label:{
					color:"#000"
				}
			}
			// MuiPaper: {
			// 	root: {
			// 		padding: "20px 10px",
			// 		margin: "10px",
			// 		backgroundColor: "#fff", // 5d737e
			// 	},
			// },
			// MuiButton: {
			// 	root: {
			// 		margin: "5px",
			// 	},
			// },
		},
	},
	ruRU
);
export default theme;
