import React, { useEffect, useState } from "react";
import {
	Box,
	Card,
	CardContent,
	CircularProgress,
	IconButton,
	LinearProgress,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import AchivmentsIcon from "../Icons/AchivmentsIcon";
import WinnerIcon from "../Icons/WinnerIcon";
import WinIcon from "../Icons/WinIcon";
import AmazedIcon from "../Icons/AmazedIcon";
import GroupIcon from "../Icons/GroupIcon";
import GodfatherIcon from "../Icons/GodfatherIcon";
import ManiacIcon from "../Icons/ManiacIcon";
import { useParams } from "react-router";
import League1Icon from "../Icons/League1Icon";
import League2Icon from "../Icons/League2Icon";
import League3Icon from "../Icons/League3Icon";
import League4Icon from "../Icons/League4Icon";
import League5Icon from "../Icons/League5Icon";

import MafiaPNG from "../../Assets/cards/mafia.png";
import ManiacPNG from "../../Assets/cards/maniac.png";
import CitizenPNG from "../../Assets/cards/citizen.png";
import WinnerPNG from "../../Assets/cards/winner.png";
import WinPNG from "../../Assets/cards/win.png";
import AmazedPNG from "../../Assets/cards/amazed.png";

const useStyles = makeStyles((theme) => ({
	menuButton: {
		marginRight: theme.spacing(2),
	},
	title: {
		padding: theme.spacing(0, 0, 0, 1),
		flexGrow: 1,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(4, 2),
		maxWidth: 1225,
		margin: "auto",
		textAlign: "center",
	},
	card: {
		// minWidth: 275,
		background: "#000",
	},
	cardComplete: {
		borderColor: "#C69B66",
	},
	cardCompleteTitle: {
		color: "#C69B66",
	},
	category: {
		marginTop: theme.spacing(4),
		textAlign: "initial",
	},
	table: {
		// marginTop: theme.spacing(4),
	},
	achivments: {
		margin: theme.spacing(2, 0),
		display: "grid",
		gridTemplateColumns: "repeat(auto-fill, minmax(290px, 1fr))",
		gap: theme.spacing(2),
	},
	leagueIcon: {
		width: 100,
		height: 100,
	},
	achivmentsIcon: {
		width: 85,
		height: 85,
	},
	cardContent: {
		height: "100%",
		padding: theme.spacing(2, 1),
		paddingBottom: "16px !important",
	},
	cardBody: {
		display: "grid",
		gridTemplateColumns: "100px 1fr",
		justifyItems: "center",
		alignItems: "center",
		justifyContent: "center",
		alignContent: "center",
		height: "100%",
		gap: 8,
	},
	cardDescription: {
		width: "100%",
		textAlign: "center",
		height: "100%",
		display: "grid",
		gridTemplateRows: "auto minmax(40px, auto) auto",
		alignItems: "center",
	},
	progress: {
		background: "#333",
	},
	progressBar: {
		background: "#fff",
	},
	achivmentProgress0: {
		filter: "grayscale(1)",
	},
	achivmentProgress10: {
		filter: "grayscale(0.95)",
	},
	achivmentProgress20: {
		filter: "grayscale(0.9)",
	},
	achivmentProgress30: {
		filter: "grayscale(0.85)",
	},
	achivmentProgress40: {
		filter: "grayscale(0.80)",
	},
	achivmentProgress50: {
		filter: "grayscale(0.75)",
	},
	achivmentProgress60: {
		filter: "grayscale(0.70)",
	},
	achivmentProgress70: {
		filter: "grayscale(0.65)",
	},
	achivmentProgress80: {
		filter: "grayscale(0.60)",
	},
	achivmentProgress90: {
		filter: "grayscale(0.55)",
	},
	achivmentProgress100: {
		filter: "grayscale(0)",
	},

	circularBottom: {
		color: "#333",
	},
	circularTop: {
		color: "#C69B66",
	},

	circular_mafia: {
		color: "#AF3737",
	},
	circular_citizen: {
		color: "#787470",
	},
	circular_commissar: {
		color: "#C69B66",
	},
	circular_doctor: {
		color: "#8ECAE2",
	},
	circular_don: {
		color: "#DE8B2B",
	},
	circular_kamikaze: {
		color: "#A98E6F",
	},
	circular_maniac: {
		color: "#B7D0CC",
	},
	circular_martyr: {
		color: "#F1F384",
	},
	circular_putana: {
		color: "#AC7AAA",
	},
	statContainer: {
		gap: theme.spacing(2),
		display: "grid",
		alignContent: "center",
		justifyContent: "space-around",
		alignItems: "center",
		justifyItems: "center",
		marginTop: theme.spacing(4),
		[theme.breakpoints.up(812)]: {
			gridTemplateColumns: "1fr 2fr",
		},
	},
	topContainer: {
		gap: theme.spacing(2),
		display: "grid",
		alignContent: "center",
		justifyContent: "center",
		alignItems: "center",
		justifyItems: "center",
		marginTop: theme.spacing(4),
		gridTemplateColumns: "repeat(auto-fit, 150px)",
		// [theme.breakpoints.up(812)]: {
		// 	gridTemplateColumns: "1fr 2fr",
		// },
	},
}));

function Profile() {
	const classes = useStyles();

	let { card } = useParams();

	const [nickname, setNickname] = useState("");
	const [achivments, setAchivments] = useState([]);
	const [leagueData, setLeagueData] = useState({});
	const [userData, setUserData] = useState({});
	const [rolesData, setRolesData] = useState([]);

	function getClassByRole(roleName) {
		return classes[`circular_${roleName}`];
	}

	function CircularRoles(props) {
		let prevPart = 100;
		let size = 270;
		let top = 0;
		return (
			<Box position="relative">
				{rolesData
					.sort((r1, r2) => r1.part - r2.part)
					.map((roleData, index) => {
						prevPart -= roleData.part;
						let cPart = prevPart + roleData.part;
						// size++;
						// top++;
						return index != rolesData.length - 1 ? (
							<Box top={top} left={0} bottom={0} right={0} position="absolute">
								<CircularProgress
									size={`${size}px`}
									className={getClassByRole(roleData.id)}
									variant="determinate"
									value={cPart}
								/>
							</Box>
						) : (
							<CircularProgress
								size={`${size}px`}
								className={getClassByRole(roleData.id)}
								variant="determinate"
								value={cPart}
							/>
						);
					})}

				<Box
					top="0px"
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="h5" component="div" style={{ width: "210px" }}>
						Распределение ролей
					</Typography>
				</Box>
			</Box>
		);
	}

	function CircularProgressWithLabel(props) {
		// let data = props.data;
		// for (let role )

		return (
			<Box position="relative">
				<Box top={0} left={0} bottom={0} right={0} position="absolute">
					<CircularProgress
						size="100px"
						className={classes.circularBottom}
						variant="determinate"
						value="100"
					/>
				</Box>
				<CircularProgress
					size="100px"
					className={classes.circularTop}
					variant="determinate"
					{...props}
				/>

				<Box
					top="-5px"
					left={0}
					bottom={0}
					right={0}
					position="absolute"
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="h3" component="div">
						{props.lvl}
					</Typography>
				</Box>
			</Box>
		);
	}

	useEffect(() => {
		async function fetchData() {
			let achivmentsRequest = await fetch(
				"https://db.izba-club.ru/achivments?access_token=ratingizbaru",
				{
					headers: {
						accept: "application/json, text/plain, */*",
						"cache-control": "no-store",
					},
					method: "GET",
				}
			);
			setAchivments(await achivmentsRequest.json());

			let cardRequest = await fetch(
				`https://db.izba-club.ru/achivments/card/${card}?access_token=ratingizbaru`,
				{
					headers: {
						accept: "application/json, text/plain, */*",
						"cache-control": "no-store",
					},
					method: "GET",
				}
			);
			let cardData = await cardRequest.json();
			if (cardData.data) setUserData(cardData.data);
			if (cardData.league) setLeagueData(cardData.league);
			setNickname(cardData?.nickname || "");

			let newRolesData = [];
			let roles = {
				mafia: "Мафия",
				citizen: "Мирный",
				commissar: "Комиссар",
				doctor: "Доктор",
				don: "Дон",
				kamikaze: "Камикадзе",
				maniac: "Маньяк",
				martyr: "Сапёр",
				putana: "Путана",
			};
			let all_games = 0;
			for (let role in roles) {
				let efficiency =
					(cardData.data[`${role}_win`] / cardData.data[`${role}_count`]) * 100;
				newRolesData.push({
					id: role,
					name: roles[role],
					win: cardData.data[`${role}_win`] || 0,
					all: cardData.data[`${role}_count`] || 0,
					lose: cardData.data[`${role}_lose`] || 0,
					efficiency: (isNaN(efficiency) ? 0 : efficiency).toFixed(2),
					part: 0,
					part_full: "",
				});
				all_games += cardData.data[`${role}_count`] || 0;
			}
			for (let roleData of newRolesData) {
				roleData.part = parseInt((roleData.all / all_games) * 100) || 1;
				let part_full = (roleData.all / all_games) * 100;
				roleData.part_full = (isNaN(part_full) ? 0 : part_full).toFixed(2);
			}
			// newRolesData.sort((r1,r2)=>r2.all-r1.all);
			setRolesData(newRolesData);
		}
		fetchData();
	}, []);

	function getClassByProgress(progress) {
		switch (progress) {
			case 0:
				return classes.achivmentProgress0;
			case 10:
				return classes.achivmentProgress10;
			case 20:
				return classes.achivmentProgress20;
			case 30:
				return classes.achivmentProgress30;
			case 40:
				return classes.achivmentProgress40;
			case 50:
				return classes.achivmentProgress50;
			case 60:
				return classes.achivmentProgress60;
			case 70:
				return classes.achivmentProgress70;
			case 80:
				return classes.achivmentProgress80;
			case 90:
				return classes.achivmentProgress90;
			case 100:
				return classes.achivmentProgress100;

			default:
				return classes.achivmentProgress0;
		}
	}

	function getImageByID(imageID, className) {
		let pngStyles = {
			maxWidth: "100%",
			maxHeight: "100%",
			width: "100%",
			height: "100%",
			objectFit: "contain",
		};
		switch (imageID) {
			case "winner":
				return (
					<img src={WinnerPNG} className={className} alt="" style={pngStyles} />
				);
			// return <WinnerIcon className={className} />;
			case "win":
				return (
					<img src={WinPNG} className={className} alt="" style={pngStyles} />
				);
			// return <WinIcon className={className} />;
			case "amazed":
				return (
					<img src={AmazedPNG} className={className} alt="" style={pngStyles} />
				);
			// return <AmazedIcon className={className} />;
			case "group":
				return (
					<img
						src={CitizenPNG}
						className={className}
						alt=""
						style={pngStyles}
					/>
				);
			// return <GroupIcon className={className} />;
			case "godfather":
				return (
					<img src={MafiaPNG} className={className} alt="" style={pngStyles} />
				);
			// return <GodfatherIcon className={className} />;
			case "maniac":
				return (
					<img src={ManiacPNG} className={className} alt="" style={pngStyles} />
				);
			// return <ManiacIcon className={className} />;
			case "league_1":
				return <League1Icon className={className} />;
			case "league_2":
				return <League2Icon className={className} />;
			case "league_3":
				return <League3Icon className={className} />;
			case "league_4":
				return <League4Icon className={className} />;
			case "league_5":
				return <League5Icon className={className} />;
			default:
				return <AchivmentsIcon className={className} />;
		}
	}

	return (
		<main className={classes.content}>
			{/* <div className={classes.drawerHeader} /> */}
			<Typography variant="h3">Клубная карта №{card}</Typography>
			<Typography variant="h4">{nickname}</Typography>
			{/* <Typography variant="caption" color="textSecondary">
				*информация обновляется ежедневно в --:--
			</Typography> */}
			<div className={classes.topContainer}>
				<Box>
					<Typography variant="subtitle1">Уровень</Typography>
					<CircularProgressWithLabel
						value={
							~~(
								((((userData && userData._points - userData._prev_lvl_ponts) ||
									0) /
									((userData &&
										userData._next_lvl_ponts - userData._prev_lvl_ponts) ||
										0)) *
									100) /
								10
							) * 10
						}
						lvl={(userData && userData._lvl) || 0}
					/>
					<Typography variant="subtitle2" color="textSecondary">
						{(userData && userData._points - userData._prev_lvl_ponts) || 0} из{" "}
						{userData._next_lvl_ponts - userData._prev_lvl_ponts || 0}
					</Typography>
					<Tooltip title="Баллов рейтинга до следующего уровня">
						<IconButton size="small" aria-label="Подсказка">
							<HelpOutline />
						</IconButton>
					</Tooltip>
				</Box>
				{/* <Box>
					<Typography variant="subtitle1">Лига</Typography>
					{leagueData?.icon
						? getImageByID(leagueData?.icon, classes.leagueIcon)
						: (<div className={classes.leagueIcon} />)}
					<Typography variant="subtitle2" color="textSecondary">
						{leagueData?.name || ""}
					</Typography>
					<Tooltip title="Текущая лига">
						<IconButton size="small" aria-label="Подсказка">
							<HelpOutline />
						</IconButton>
					</Tooltip>
				</Box> */}
			</div>
			{/* <Box marginTop="16px">
				<Typography variant="subtitle1">Роли</Typography>
				<CircularRoles />
			</Box> */}
			<div className={classes.statContainer}>
				<CircularRoles />
				<TableContainer className={classes.table} component={Paper}>
					<Table size="small" aria-label="Статистика">
						<TableHead>
							<TableRow>
								<TableCell align="center">Роль</TableCell>
								<TableCell align="center">Процент побед</TableCell>
								<TableCell align="center">Распределение ролей</TableCell>
								<TableCell align="center">Всего игр</TableCell>
								<TableCell align="center">Всего побед</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rolesData
								.sort((r1, r2) => r2.all - r1.all)
								.map((roleData) => (
									<TableRow key={roleData.id}>
										<TableCell
											className={getClassByRole(roleData.id)}
											align="center"
											style={{ fontSize: "1.2rem" }}
										>
											{roleData.name}
										</TableCell>
										<TableCell align="center">{roleData.efficiency}%</TableCell>
										<TableCell align="center">{roleData.part_full}%</TableCell>
										<TableCell align="center">{roleData.all}</TableCell>
										<TableCell align="center">{roleData.win}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</div>

			<div>
				{achivments
					.filter((category) => category.active)
					.sort((c1, c2) => c1.priority - c2.priority)
					.map((category) => {
						return (
							<div className={classes.category}>
								<Typography variant="h4">{category.name}</Typography>
								<div className={classes.achivments}>
									{category.achivments_list
										.sort((c1, c2) => c1.priority - c2.priority)
										.map((achivment) => {
											let current = userData[achivment.field] || 0;
											if (current > achivment.goal) current = achivment.goal;
											let progress =
												~~(((current / achivment.goal) * 100) / 10) * 10;
											achivment.progress = progress;
											achivment.html = (
												<Card
													className={`${classes.card}${
														progress == 100 ? ` ${classes.cardComplete}` : ""
													}`}
													variant="outlined"
												>
													<CardContent className={classes.cardContent}>
														<div className={classes.cardBody}>
															{getImageByID(
																achivment.image,
																`${classes.achivmentsIcon} ${getClassByProgress(
																	progress
																)}`
															)}
															<div className={classes.cardDescription}>
																<Typography
																	variant="h6"
																	component="h2"
																	className={
																		progress == 100
																			? classes.cardCompleteTitle
																			: ""
																	}
																>
																	{progress == 100
																		? achivment.title
																		: "? ? ? ? ?"}
																</Typography>
																<Typography variant="body2" component="p">
																	{achivment.description}
																</Typography>
																<Typography
																	className={classes.pos}
																	color="textSecondary"
																>
																	{progress < 100
																		? `${current} из ${achivment.goal}`
																		: "Завершено"}
																</Typography>
																<LinearProgress
																	className={classes.progress}
																	classes={{ bar: classes.progressBar }}
																	variant="determinate"
																	value={progress}
																/>
															</div>
														</div>
													</CardContent>
												</Card>
											);
											return achivment;
										})
										.sort((a1, a2) => {
											if (a1.progress == a2.progress) return 0;
											if (a1.progress == 100) return -1;
											if (a2.progress == 100) return 1;
											return 0;
										})
										.map((achivment) => achivment.html)}
								</div>
							</div>
						);
					})}
			</div>
		</main>
	);
}

export default Profile;
